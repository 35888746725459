$text-black: #2b2b2b;

.ticket-card {
  background-color: #fff;
  color: $text-black;

  .header5 {
    margin: 0;
  }

  // Elements

  &__flight-details {
    display: flex;
    justify-items: center;
    align-items: center;
  }

  &__price {
    display: flex;
    flex-direction: column;
  }

  &__flight-route-details {
    display: flex;
    flex-direction: column;
  }

  &__flight-route-to {
    display: flex;
  }

  &__flight-time {
    display: flex;
  }

  &__flight-route-from {
    display: flex;
  }

  // sm
  @media screen and (min-width: 576px) {
    &__flight-route-details {
      flex-direction: row;
      align-items: center;
    }

    &__flight-route-to,
    &__flight-time,
    &__flight-route-from {
      flex-direction: column;
    }
  }

  // md
  @media screen and (min-width: 768px) {
  }

  // lg
  @media screen and (min-width: 992px) {
  }

  // xl
  @media screen and (min-width: 1200px) {
  }
}

.tickets-card-group {
  &__content {
    display: flex;
    gap: 0.75rem;
    flex-direction: column;
  }
}
