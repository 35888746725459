$text-gray-02: #a4a4a4; // Structured vars
$text-black: #2b2b2b; // todo move to vars
$primary-color: #ff7171;

.jetsaver {
  .tabs {
    background: #fff;
    box-shadow: 0 0.75rem 2rem rgb(38 55 116 / 10%);
    border-radius: 0.75rem;

    .p-tabview-nav {
      li {
        &:not(.p-highlight, .p-disabled):hover {
          .p-tabview-nav-link {
            border-color: #e5e9f0; // line divider color
          }
        }

        a {
          height: 3.5rem;
        }

        .p-tabview-nav-link {
          //
          font-style: normal;
          font-weight: 500;
          font-size: 0.9375rem;
          line-height: 1.5rem;
          text-align: center;

          //
          color: $text-gray-02;
        }

        &.p-highlight {
          .p-tabview-nav-link {
            color: $text-black;
            border-color: $primary-color;
          }
        }
      }
    }

    .p-tabview-nav-content {
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
    }

    .p-tabview-panels {
      padding: 0;
      border-bottom-left-radius: 0.75rem;
      border-bottom-right-radius: 0.75rem;
    }

    &--header-centered {
      .p-tabview-nav {
        justify-content: center;
      }
    }

    // Modifiers
    &--account {
      width: 70rem;
    }
  }
}
