.empty-flights-result {
  margin-top: 2rem;
  text-align: center;

  .header2 {
    margin-bottom: 0.5rem;
  }

  .header4 {
    margin: 0.5rem 0;
  }
}
