/* stylelint-disable selector-no-vendor-prefix */
// Jetsaver theme

$primary-color: #ff7171;
$primary-text-color: #2b2b2b;
$input-border-color: #cbcbcb;
$color-white: #fff;
$bg-light-grey: #f4f7fb; // vars
$text-gray-02: #a4a4a4;
$text-black: #2b2b2b; // todo move to vars

//
$placeholder-color: $text-gray-02;

.jetsaver {
  .p-dialog {
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .p-button {
    border-radius: 0.5rem; // var
    font-weight: 700;
    font-size: 0.9375rem;
    line-height: 1.5rem;

    &:focus {
      box-shadow: 0 0 0 0.2rem #f5f5f5;
    }
  }

  .p-inputtext {
    border-radius: 0.5rem;
    border: 1px solid #cbcbcb;
    padding: 0.7rem;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    color: $text-black;

    &.p-invalid {
      border-color: $primary-color;
    }
  }

  .p-float-label > label {
    color: #a4a4a4;
  }

  .p-dropdown {
    border-radius: 0.5rem;
    border: 1px solid #cbcbcb;

    // padding: 0.7rem;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    color: $text-black;

    &.p-invalid {
      border-color: $primary-color;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem #f5f5f5;
    }

    .p-dropdown-trigger {
      color: #cbcbcb;
    }
  }

  .p-datepicker-trigger {
    background: #a4a4a4;
    border-color: #a4a4a4;
  }

  .p-inputtext:disabled {
    background: $bg-light-grey;
    color: $text-black;
    opacity: 1;
  }

  .p-inputtext:focus {
    box-shadow: 0 0 0 0.2rem #f5f5f5;
  }

  .p-button:focus {
    outline: 0 none;
    outline-offset: 0;
  }

  .p-calendar {
    &.p-invalid input {
      border-color: $primary-color;
    }
  }

  .p-checkbox {
    width: 1.5rem;
    height: 1.5rem;

    .p-checkbox-box {
      width: 1.5rem;
      height: 1.5rem;

      .p-checkbox-icon {
        color: $primary-color;
      }
    }

    &.p-highlight {
      .p-checkbox-box {
        background: $color-white;
        border-color: $input-border-color;
      }
    }

    .p-checkbox-input {
      border: 2px solid $input-border-color;
    }
  }

  .p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
    border-color: $input-border-color;
  }

  .main-button {
    &.p-button {
      background-color: $primary-color;
      border: 0 solid $primary-color;
      padding: 0.75rem 1.75rem; // todo common?

      &:focus {
        box-shadow: 0 0 0 0.2rem #fcdddd;
      }
    }

    //Modifiers
    &--w-full {
      width: 100%;
    }
  }

  .secondary-button {
    &.p-button {
      color: #2b2b2b;
      background: #fff;
      border: 1px solid $input-border-color;
      padding: 0.75rem 1.75rem;

      &:focus {
        box-shadow: 0 0 0 0.2rem #f5f5f5;
      }
    }

    &.p-button-lg {
      font-size: 0.9375rem;
      font-weight: bold;
      padding: 0.75rem 1.75rem;
    }

    &--w-full {
      width: 100%;
    }
  }

  // Informer theme style
  .p-error {
    color: $primary-color;
  }

  // Typography

  .header1 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .header2 {
    font-style: normal;
    font-size: 1.75rem;
    line-height: 2.75rem;

    //
    color: $text-black;
    font-weight: 800;

    &--form {
      margin: 0;
      width: 100%;
    }
  }

  .header3 {
    font-style: normal;
    font-weight: 700;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    color: $text-black;
  }

  .header4 {
    font-style: normal;
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  .header5 {
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  .content-b1 {
    font-style: normal;
    font-weight: 700;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    color: $text-black;

    &--form {
      margin: 0;
      margin-top: 0.25rem;
    }
  }

  .content-b3 {
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    text-align: center;
    color: $text-black;
  }

  .content-s1 {
    font-style: normal;
    font-weight: 700;
    font-size: 0.6875rem;
    line-height: 1.125rem;
  }

  .content-s2 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.6875rem;
    line-height: 1.125rem;
    color: $text-gray-02;
  }

  .content-s3 {
    font-style: normal;
    font-weight: 500;
    font-size: 0.6875rem;
    line-height: 1.125rem;
  }

  .checkbox-label {
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    color: $text-black;
    text-align: left;
  }

  .p-instruction {
    background-color: #f4f7fb;
    border-radius: 0.75rem;
    padding: 1.25rem;
  }

  //End Typography

  .p-icon-field-left > .p-input-icon:first-of-type {
    left: 0.5rem;
    color: $input-border-color;
  }

  .p-icon-field-right > .p-input-icon:last-of-type {
    right: 0.5rem;
    color: $input-border-color;
  }

  ::-webkit-input-placeholder {
    color: $placeholder-color;
  }

  :-moz-placeholder {
    color: $placeholder-color;
  }

  ::-moz-placeholder {
    color: $placeholder-color;
  }

  :-ms-input-placeholder {
    color: $placeholder-color;
  }

  // md
  @media screen and (min-width: 768px) {
    .header1 {
      font-weight: 700;
      font-size: 2.25rem;
      line-height: 3.25rem;
    }

    .header2 {
      font-size: 2.125rem;
      line-height: 3.25rem;
    }

    .header3 {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }

  // lg
  @media screen and (min-width: 992px) {
    .header1 {
      font-weight: 800;
      font-size: 2.75rem;
      line-height: 4rem;
    }

    .header2 {
      font-size: 2.375rem;
      line-height: 3.5rem;
    }

    .header3 {
      font-size: 1.375rem;
      line-height: 1.875rem;
    }
  }

  // xl
  @media screen and (min-width: 1200px) {
    .header1 {
      font-weight: 800;
      font-size: 3em;
      line-height: 4.5rem;
    }

    .header2 {
      font-size: 2.5rem;
      line-height: 3.75rem;
    }

    .header3 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
}

// Additional theme styles

.p-informer {
  font-style: normal;
  font-weight: 400;
  font-size: 0.6875rem;
  line-height: 1.125rem;
  color: $primary-text-color;

  &--align-center {
    text-align: center;
  }

  &--align-right {
    text-align: right;
  }

  &__link {
    color: $primary-text-color;

    &:hover {
      color: $primary-text-color;
    }

    &:visited {
      color: $primary-text-color;
    }

    &:active {
      color: $primary-text-color;
    }
  }
}

//

.main-content {
  max-width: 77.5rem;
}

// Welcome block

.welcome-block {
  width: 100%;
  text-align: center;
  padding-top: 0.5rem;

  &__image {
    display: none;
  }

  .header1 {
    margin: 0.5rem;
  }

  .header3 {
    margin: 0.25rem;
  }

  @media screen and (min-width: 576px) {
    &__image {
      display: inline;
    }
  }
}

.blur-effect {
  filter: blur(3px);
}
