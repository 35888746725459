.jetsaver {
  .page {
    background: #fff; // Todo from vars

    &-home {
      background: #f8f8f8;

      .form--search-ticket {
        margin-top: 1rem;
      }
    }

    &-login {
      background: #f8f8f8;

      .form--landing-login-form {
        margin-top: 1rem;
      }
    }

    //sm
    @media screen and (min-width: 576px) {
      &-home {
        .form--search-ticket {
          margin-top: 1rem;
        }
      }
    }

    // md
    @media screen and (min-width: 768px) {
      &-home {
        .form--search-ticket {
          margin-top: 1.25rem;
        }
      }
    }

    // lg
    @media screen and (min-width: 992px) {
      &-home {
        .form--search-ticket {
          margin-top: 1.25rem;
        }
      }

      &-login {
        .form--landing-login-form {
          margin-top: 1.25rem;
        }
      }
    }

    // xl
    @media screen and (min-width: 1200px) {
      &-home {
        .form--search-ticket {
          margin-top: 1.25rem;
        }
      }
    }
  }
}
