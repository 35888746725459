.jetsaver {
  .page-auth-preloader {
    width: 100%;
    height: 100%;
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
