.jetsaver {
  .flight-photos-gallery {
    max-width: 98%;
    margin: 0 auto;

    .p-galleria-item-container {
      height: 450px;

      .p-galleria-item {
        img {
          border-radius: 0.5rem;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .p-galleria-thumbnail-container {
      background: #fff;
      padding: 1rem 0;

      .p-galleria-thumbnail-prev {
        color: #444;
      }

      .p-galleria-thumbnail-next {
        color: #444;
      }
    }

    .p-galleria-thumbnail-item-content {
      cursor: pointer;
      opacity: 0.75;
      transition: opacity 0.3s;

      img {
        border-radius: 0.5rem;
        width: 80px;
        height: 80px;
        object-fit: cover;
      }

      &:hover {
        opacity: 1;
      }
    }

    .p-galleria-thumbnail-item.p-galleria-thumbnail-item-current {
      .p-galleria-thumbnail-item-content {
        opacity: 1;
      }
    }
  }
}
