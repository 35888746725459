.thumbnail-container {
  position: relative;
  overflow: hidden;
  background-color: #f0f0f0;
  width: 6em;
  height: 6em;
  border-radius: 0.5rem;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.thumbnail.loaded {
  opacity: 1;
}
