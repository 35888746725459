$text-black: #2b2b2b; // todo move to vars
$text-white: #fff;

.jetsaver {
  .user-avatar-container {
    position: relative;
  }

  .user-avatar-container .p-avatar {
    transition: opacity 0.2s;
    background: $text-black;
    color: $text-white;
  }

  .user-avatar-container .p-avatar:hover {
    opacity: 0.8;
  }

  .user-menu .p-menu-list {
    min-width: 200px;
  }

  .user-menu .p-menuitem-link {
    padding: 0.75rem 1rem;
  }
}
