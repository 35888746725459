@import 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap';

:root {
  --font-family: 'Poppins', sans-serif;

  font-family: Poppins, sans-serif;

  --gray-100:#F8F8F8;
  --maskbg: rgb(0 0 0 / 15%);
}

html{
  font-size: 16px;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;

  /* width: 100%; */
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}
