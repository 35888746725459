$color-black: #2b2b2b;
$text-gray-02: #a4a4a4;
$text-white: #fff;
$bg-light-grey: #f4f7fb;

.jetsaver {
  .chip {
    &--ticket-card-views {
      background-color: $bg-light-grey;

      // S3 - todo use mixin
      font-style: normal;
      font-weight: 500;
      font-size: 0.6875rem;
      line-height: 1.125rem;

      //
      color: $text-gray-02;
    }

    &--ticket-card-info {
      background-color: $color-black;

      // S1 -todo use mixin
      font-style: normal;
      font-weight: 700;
      font-size: 0.6875rem;
      line-height: 1.125rem;

      //
      color: $text-white;
    }
  }
}
