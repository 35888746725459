/* stylelint-disable selector-class-pattern */

.header {
  &__menu {
    height: 100%;

    // todo change for mobile
    margin-left: 2.5rem;

    &.p-component {
      font-weight: 400;
      font-size: 0.9375rem;
    }

    &.p-menubar {
      padding: 0;
      border: none;
      border-radius: 0;
      background-color: white;

      .p-menubar-root-list {
        height: 100%;

        // unset block for mobile
        display: flex;
        align-items: flex-start;
        gap: 1.8125rem;

        //

        > .p-menuitem {
          // unset block for mobile
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          //
          border-bottom: 2px solid transparent;

          > .p-menuitem-content {
            .p-menuitem-link {
              .p-menuitem-text {
                color: #a4a4a4;
              }
            }
          }

          &.p-menuitem-active {
            border-bottom: 2px solid #ff7171; // use var?

            > .p-menuitem-content {
              .p-menuitem-link {
                .p-menuitem-text {
                  // font-size: 0.9375rem;
                  color: #2b2b2b;
                }
              }
            }
          }
        }
      }
    }
  }

  background-color: #fff;
  border-bottom: 1px solid #e1e1e1;

  //
  min-height: 5.625rem;
  padding: 0;

  //

  @media screen and (min-width: 576px) {
    // padding: 0 6.25rem;
  }

  @media screen and (min-width: 768px) {
    // padding: 0 6.25rem;
  }

  @media screen and (min-width: 992px) {
    // padding: 0 6.25rem;
  }

  @media screen and (min-width: 1200px) {
    // padding: 0 6.25rem;
  }
}

.logo {
  width: 9.875rem;
  height: 2rem;
  margin-left: 0;

  @media screen and (min-width: 768px) {
    margin-left: 2.5rem;
  }
}

.login-button {
  margin-right: 0;

  @media screen and (min-width: 768px) {
    margin-right: 2.5rem;
  }
}

//
